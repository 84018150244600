import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "How to work with Alarmserver Queries",
  "path": "/Advanced_User/Alarmserver_Queries_for_your_Smarthome/",
  "dateChanged": "2019-09-20",
  "author": "Mike Polinowski",
  "excerpt": "Use the INSTAR Alarmserver Queries to automate your Home.",
  "image": "./AU_SearchThumb_Node-RED.png",
  "social": "/images/Search/AU_SearchThumb_Node-RED.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_NodeRED_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Node-RED"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='How to work with Alarmserver Queries' dateChanged='2019-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the INSTAR Alarmserver Queries to automate your Home.' image='/images/Search/AU_SearchThumb_Node-RED.png' twitter='/images/Search/AU_SearchThumb_Node-RED.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Alexa_Echo5_and_Monocle_Gateway/' locationFR='/fr/Advanced_User/Alexa_Echo5_and_Monocle_Gateway/' crumbLabel="AS Queries" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "how-to-work-with-alarmserver-queries",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-to-work-with-alarmserver-queries",
        "aria-label": "how to work with alarmserver queries permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to work with Alarmserver Queries`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#setup"
        }}>{`Setup`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#what-we-want"
            }}>{`What we want`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#node-red-flow"
            }}>{`Node-RED Flow`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#alarmserver-configuration"
            }}>{`Alarmserver Configuration`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#testing"
            }}>{`Testing`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#import-the-flow-into-node-red"
        }}>{`Import the Flow into Node-RED`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`INSTAR IP cameras offer an Alarmserver Function - `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`1080p Models`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/Alarmserver/"
      }}>{`720p Models`}</a>{` - that allows you to send a request to a home automation system on your network. Thus, notifying your smarthome and trigger an response.`}</p>
    <p>{`In an recent update we introduced parameters (`}<strong parentName="p">{`URL Queries`}</strong>{`) that can be appended to this HTTP GET request. There are three custom parameters available as well as a specified parameter that can tell us what triggered the alarm. The latter - called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`active`}</code>{` can have the following 10 values:`}</p>
    <ul>
      <li parentName="ul">{`Alarm Area 1 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=1`}</code></li>
      <li parentName="ul">{`Alarm Area 2 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=2`}</code></li>
      <li parentName="ul">{`Alarm Area 3 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=3`}</code></li>
      <li parentName="ul">{`Alarm Area 4 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=4`}</code></li>
      <li parentName="ul">{`Alarm-In / PIR triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=5`}</code></li>
      <li parentName="ul">{`Audio Alarm triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=6`}</code></li>
      <li parentName="ul">{`Motion Detection & triggered (Area 1): `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=7`}</code></li>
      <li parentName="ul">{`Motion Detection & triggered (Area 2): `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=8`}</code></li>
      <li parentName="ul">{`Motion Detection & triggered (Area 3): `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=9`}</code></li>
      <li parentName="ul">{`Motion Detection & triggered (Area 4): `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=10`}</code></li>
      <li parentName="ul">{`Manual trigger (`}<em parentName="li">{`cmd=pushhostalarm`}</em>{`): `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=99`}</code></li>
    </ul>
    <p>{`We now want to build a Webhook in Node-RED - as a placeholder for one of the many smarthome solutions available - and have this Webhook trigger different actions depending on the `}<strong parentName="p">{`URL Query`}</strong>{` it receives.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setup",
        "aria-label": "setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setup`}</h2>
    <ul>
      <li parentName="ul">{`First camera: IP=192.168.2.116, port=80, name=frontdoor, login=admin/instar`}</li>
      <li parentName="ul">{`Second camera: IP=192.168.2.117, port=80, name=garden, login=admin/instar`}</li>
      <li parentName="ul">{`Node-RED Server: IP=192.168.2.48, port=1880`}</li>
    </ul>
    <h3 {...{
      "id": "what-we-want",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-we-want",
        "aria-label": "what we want permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What we want`}</h3>
    <p>{`If our first camera is triggered we want it to contact the Node-RED Webhook, identify itself as `}<strong parentName="p">{`frontdoor`}</strong>{` and if the alarm was triggered by an `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`audio detection`}</a>{` (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`&active=6`}</code>{`), we want to camera to move to stored `}<em parentName="p">{`position 3`}</em>{` - which is where we estimate that the noise will be coming from. If the alarm was triggered by the internal motion detection (so the query will be `}<strong parentName="p">{`not`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`&active=6`}</code>{`), we want to contact our second camera `}<strong parentName="p">{`garden`}</strong>{` and have it swing around (`}<em parentName="p">{`position 2`}</em>{`) to check out the area, where the motion was detected.`}</p>
    <p>{`And vice versa - if our second camera triggers an alert it should contact the Node-RED Webhook, identify as `}<strong parentName="p">{`garden`}</strong>{`, and in case of an audio alarm have it move to `}<em parentName="p">{`position 3`}</em>{`. If the alarm trigger was not the audio detection the `}<strong parentName="p">{`frontdoor`}</strong>{` camera should be contacted instead and move to `}<em parentName="p">{`position 2`}</em>{`.`}</p>
    <p>{`To reset our setup after an alarm trigger, we can set `}<em parentName="p">{`position 1`}</em>{` to be our cameras `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ/"
      }}>{`Park Position`}</a>{`. Or actively send a command to our camera to go back to the idle position - with a delay of our choosing.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "/en/Software/Home_Automation/Node-RED/#9-interconnect-your-cameras"
      }}>{`Node-RED Smarthome Overview`}</a>{` for an use case.`}</p>
    <h3 {...{
      "id": "node-red-flow",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#node-red-flow",
        "aria-label": "node red flow permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Flow`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "795px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f09f7295cc700d5d92489bdddf4d605e/65c7b/INSTAR_Alarmserver_Queries_in_Node-RED_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB1ElEQVQoz4WSy2/TQBDG8/+fK44cOCBxrDhVQISgERAFNY+GuPHba3sdko3X9q73vYtaBw5uJb7bHH7fzDczEzeStc45SesuXdB8yeipbbO2zbWW7pkm1jrGUF3HdZ1gnNR1ROjpXAT18l27ve7OCTqvTseNlP3gPYItxlFZ/oBwUVU/q2qOkNd21Fr3X01GtdZWCK2UdEYaLYwxCCVZ9iVJpmX5behvrbZP3gNstdGM9bSnQgrOZQmmwa9XoXeVp9dxBAIv8ndxAbKqWqTpNE4+YBxdYCEEJUQJ5oxy1jDWYwwbDBqcU9ocDkEcTYPgI4RzIYjWXKneGHWBmZANDJvtDVq+b3af8ekhDV+n/lWR3mBMH8d8GtI+W8MlszXaKGEkN0poJRjr+55xrqR0GAMIv+fFDKF7Y8wYHlm2HYX518R/G+/fVMWnNAHRPgz3UQXTw2EJwAyAW0LgC9t+vKR1tMs6vCHtusUhyLzQvw32swquKD1QCiktlSIX2GitpDBacsYYl6Nk2jAuToxVnKMXMp/rZu2F8/Xei8A527XJHQErjrKOIIR2CD10bWaM/fth9t+fTYaQflZt/Lz4jSVKSbok2Z24wNvj8b6u/eE2o85/AAt+4ENghPeLAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f09f7295cc700d5d92489bdddf4d605e/e4706/INSTAR_Alarmserver_Queries_in_Node-RED_01.avif 230w", "/en/static/f09f7295cc700d5d92489bdddf4d605e/d1af7/INSTAR_Alarmserver_Queries_in_Node-RED_01.avif 460w", "/en/static/f09f7295cc700d5d92489bdddf4d605e/70470/INSTAR_Alarmserver_Queries_in_Node-RED_01.avif 795w"],
              "sizes": "(max-width: 795px) 100vw, 795px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f09f7295cc700d5d92489bdddf4d605e/a0b58/INSTAR_Alarmserver_Queries_in_Node-RED_01.webp 230w", "/en/static/f09f7295cc700d5d92489bdddf4d605e/bc10c/INSTAR_Alarmserver_Queries_in_Node-RED_01.webp 460w", "/en/static/f09f7295cc700d5d92489bdddf4d605e/399bf/INSTAR_Alarmserver_Queries_in_Node-RED_01.webp 795w"],
              "sizes": "(max-width: 795px) 100vw, 795px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f09f7295cc700d5d92489bdddf4d605e/81c8e/INSTAR_Alarmserver_Queries_in_Node-RED_01.png 230w", "/en/static/f09f7295cc700d5d92489bdddf4d605e/08a84/INSTAR_Alarmserver_Queries_in_Node-RED_01.png 460w", "/en/static/f09f7295cc700d5d92489bdddf4d605e/65c7b/INSTAR_Alarmserver_Queries_in_Node-RED_01.png 795w"],
              "sizes": "(max-width: 795px) 100vw, 795px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f09f7295cc700d5d92489bdddf4d605e/65c7b/INSTAR_Alarmserver_Queries_in_Node-RED_01.png",
              "alt": "Alarmserver Queries in Node-RED",
              "title": "Alarmserver Queries in Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In this flow we setup a Webhook under the URL `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/as-webhook/`}</code>{` - so that we can contact it under the IP/Port of our Node-RED server + URL. In this case this is: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://192.168.2.48:1880/as-webhook/`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "851px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f2cf0612c880f0e58f2f280c4ba467d8/0fcea/INSTAR_Alarmserver_Queries_in_Node-RED_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "20%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAt0lEQVQI102OsQrCMBiE+/CKuLg6iI8gdnaqrcWa2qS1IKiIIOgojbYx5M+fSBS1H8dNd9x5jOV5Xvy1KUlBd6e9Ag0AxhgAba01LRDRWgsAHs1IugqdkpCsQrqMsvHwOBrcb1UjBGotmgYAsIXWGhFdOSNxPPcXgR8H0yj0yWyy7fQu3T4/n9U7yivOOZdSKqX0F2OMUspjjOXuuYNRmq6zpCwP14sQTyklItZ1LYR4PJz/xj/lF5fk1z9Uv5UVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2cf0612c880f0e58f2f280c4ba467d8/e4706/INSTAR_Alarmserver_Queries_in_Node-RED_02.avif 230w", "/en/static/f2cf0612c880f0e58f2f280c4ba467d8/d1af7/INSTAR_Alarmserver_Queries_in_Node-RED_02.avif 460w", "/en/static/f2cf0612c880f0e58f2f280c4ba467d8/7aa96/INSTAR_Alarmserver_Queries_in_Node-RED_02.avif 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f2cf0612c880f0e58f2f280c4ba467d8/a0b58/INSTAR_Alarmserver_Queries_in_Node-RED_02.webp 230w", "/en/static/f2cf0612c880f0e58f2f280c4ba467d8/bc10c/INSTAR_Alarmserver_Queries_in_Node-RED_02.webp 460w", "/en/static/f2cf0612c880f0e58f2f280c4ba467d8/155dd/INSTAR_Alarmserver_Queries_in_Node-RED_02.webp 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2cf0612c880f0e58f2f280c4ba467d8/81c8e/INSTAR_Alarmserver_Queries_in_Node-RED_02.png 230w", "/en/static/f2cf0612c880f0e58f2f280c4ba467d8/08a84/INSTAR_Alarmserver_Queries_in_Node-RED_02.png 460w", "/en/static/f2cf0612c880f0e58f2f280c4ba467d8/0fcea/INSTAR_Alarmserver_Queries_in_Node-RED_02.png 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f2cf0612c880f0e58f2f280c4ba467d8/0fcea/INSTAR_Alarmserver_Queries_in_Node-RED_02.png",
              "alt": "Alarmserver Queries in Node-RED",
              "title": "Alarmserver Queries in Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`When we receive a GET request via this URL, we now have to strip the URL queries that have been send with it. First we check for a query called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`camera`}</code>{` and see if it has the value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`frontdoor`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`garden`}</code>{`, to identify the camera that contacted us.`}</p>
    <p>{`An then we have to check for the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`active`}</code>{` parameter, to if the alarm was triggered by the audio alarm - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`&active=6`}</code>{` - or not. If yes, we have to return the CGI command to start a video recording and move to `}<strong parentName="p">{`position 3`}</strong>{` to the triggering camera. Otherwise, send the CGI command to move to `}<strong parentName="p">{`position 2`}</strong>{` and start recording to the other camera.`}</p>
    <p>{`And after a set time - I will choose 15s - we have to send the command to stop the recording and to move back to position 1. `}</p>
    <p>{`For our first camera those commands are as follows:`}</p>
    <p><strong parentName="p">{`ALARM ACTION`}</strong></p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.116/param.cgi?cmd=manualrec&-act=on&-time=60&cmd=preset&-act=goto&-number=2&-usr=admin&-pwd=instar`}</code>{` // if `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=6`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.117/param.cgi?cmd=manualrec&-act=on&-time=60&cmd=preset&-act=goto&-number=1&-usr=admin&-pwd=instar`}</code>{` // if `}<strong parentName="li">{`not`}</strong>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=6`}</code></li>
    </ul>
    <p><strong parentName="p">{`RESET`}</strong></p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.116/param.cgi?cmd=manualrec&-act=off&cmd=preset&-act=goto&-number=0&-usr=admin&-pwd=instar`}</code>{` // if `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=6`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.117/param.cgi?cmd=manualrec&-act=off&cmd=preset&-act=goto&-number=0&-usr=admin&-pwd=instar`}</code>{` // if `}<strong parentName="li">{`not`}</strong>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&active=6`}</code></li>
    </ul>
    <h3 {...{
      "id": "alarmserver-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarmserver-configuration",
        "aria-label": "alarmserver configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarmserver Configuration`}</h3>
    <p>{`The Alarmserver inside our camera has to be configured as follows:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "903px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6a2e345b18c50779bedf36e00d8e97a9/c4b7c/INSTAR_Alarmserver_Queries_in_Node-RED_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB60lEQVQoz22QW2sTQRTH91PZKA1YS22bkAhRC9oLCgk+mN1NYy67KxTSFGkaBJs0EZU8mOzSYmuRgiAU+5SHpt8hKFgwN0F3NzObmdk5kvUCYn/8Hw6H8z83IZVOq9mU/LgQLRwt5w9XNg6XNt5dqOjm0YPi+9R6KZ9by63nc7mckM5kM48Sa09rL5tm+eOgfPKt+KG3dZGqJ4NXTUsr1pKrkihKyWRSUFQtLiXe7Bmnbbt6/KXR/Lrf6u2f9g5a3bdnPS/uHrR6erNTPT4/+4RePK8sLC7PTF0LBQJCOquIklzXdROzz90f532r8x0PLNI3/9HAIu0uavdZqfTMPz11eWIiPD82qw9Fud7QAfjIQWSEucsAADgnDqIjDMA9uZSN8+Xqzkxgzufz3QiHxmZRkg3DYK5r2zb/U8tc1zQtjB36G0YIAYDtys5sODgXCt6PxYSsosalhK4bnPPhcEgpBQ/XdS3LchyHMUYpdTwAoFZ/fXtpMRiJ3IvGhIwyXns8mTHbthFCf80IIcdxCCGUUuwBAKVKdXo+eGXSH7l1U8gqWlyUG7rhemtjjDnn3skcY4wQIoQw5lJKhwhzgCeFzUuT/qvXZxfu3BVUVZPkhGEYAPCrPfMe8z82ph2T7+7tSolVRdMUVf0JyFC4Gq4p/CUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a2e345b18c50779bedf36e00d8e97a9/e4706/INSTAR_Alarmserver_Queries_in_Node-RED_03.avif 230w", "/en/static/6a2e345b18c50779bedf36e00d8e97a9/d1af7/INSTAR_Alarmserver_Queries_in_Node-RED_03.avif 460w", "/en/static/6a2e345b18c50779bedf36e00d8e97a9/158b1/INSTAR_Alarmserver_Queries_in_Node-RED_03.avif 903w"],
              "sizes": "(max-width: 903px) 100vw, 903px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6a2e345b18c50779bedf36e00d8e97a9/a0b58/INSTAR_Alarmserver_Queries_in_Node-RED_03.webp 230w", "/en/static/6a2e345b18c50779bedf36e00d8e97a9/bc10c/INSTAR_Alarmserver_Queries_in_Node-RED_03.webp 460w", "/en/static/6a2e345b18c50779bedf36e00d8e97a9/4673f/INSTAR_Alarmserver_Queries_in_Node-RED_03.webp 903w"],
              "sizes": "(max-width: 903px) 100vw, 903px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a2e345b18c50779bedf36e00d8e97a9/81c8e/INSTAR_Alarmserver_Queries_in_Node-RED_03.png 230w", "/en/static/6a2e345b18c50779bedf36e00d8e97a9/08a84/INSTAR_Alarmserver_Queries_in_Node-RED_03.png 460w", "/en/static/6a2e345b18c50779bedf36e00d8e97a9/c4b7c/INSTAR_Alarmserver_Queries_in_Node-RED_03.png 903w"],
              "sizes": "(max-width: 903px) 100vw, 903px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6a2e345b18c50779bedf36e00d8e97a9/c4b7c/INSTAR_Alarmserver_Queries_in_Node-RED_03.png",
              "alt": "Alarmserver Queries in Node-RED",
              "title": "Alarmserver Queries in Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For the other camera we just have to set the camera name to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`camera = frontdoor`}</code>{`. All done!`}</p>
    <p>{`Now every time the alarm on our camera is triggered it will contact our Node-RED Webhook and sends both it's name and the alarm trigger.`}</p>
    <h3 {...{
      "id": "testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#testing",
        "aria-label": "testing permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing`}</h3>
    <p>{`You can use the `}<strong parentName="p">{`Inject Node`}</strong>{` to test that Node-RED is set up correctly. By clicking on the node, you will send a GET request to your Webhook and transmit the following parameter as query:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "850px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e0dac4a20d25ea57f18c9a7e89605884/ae694/INSTAR_Alarmserver_Queries_in_Node-RED_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABD0lEQVQY02XPTU7DMBAF4JyXLYcoO87BplIlhKqCVMpfHCdOJpyB1E5/EseO7cbNJKiVChJ80lu+Gb0gSZIoiiiNCAkppQCQsrQQpbZWSqkv2jNzilG1DK+uYXIbPL+8zR4W0/vFcrmkT1MyvyOr2fpmIuePG619143j2Pc9XgzD4JzbbzbVbhfQOPkg5D2M4jhOySsLV3G44mmK+2oYxwHx6H1//C0jYn8+plsdAEAOkOcAABnkWf7J0uyrFK11vvOIaNr2cDj9/4GI3nvOeZD9wxjjfL3dbstSyEaq02QlZa1UU8tKqaZppLGmKIq/ZQBgjAkhtNb7XSV4qZS2VlmnjZHWaeuUda3vurquvwG7aD9XodJLHAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e0dac4a20d25ea57f18c9a7e89605884/e4706/INSTAR_Alarmserver_Queries_in_Node-RED_04.avif 230w", "/en/static/e0dac4a20d25ea57f18c9a7e89605884/d1af7/INSTAR_Alarmserver_Queries_in_Node-RED_04.avif 460w", "/en/static/e0dac4a20d25ea57f18c9a7e89605884/4c41f/INSTAR_Alarmserver_Queries_in_Node-RED_04.avif 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e0dac4a20d25ea57f18c9a7e89605884/a0b58/INSTAR_Alarmserver_Queries_in_Node-RED_04.webp 230w", "/en/static/e0dac4a20d25ea57f18c9a7e89605884/bc10c/INSTAR_Alarmserver_Queries_in_Node-RED_04.webp 460w", "/en/static/e0dac4a20d25ea57f18c9a7e89605884/10237/INSTAR_Alarmserver_Queries_in_Node-RED_04.webp 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e0dac4a20d25ea57f18c9a7e89605884/81c8e/INSTAR_Alarmserver_Queries_in_Node-RED_04.png 230w", "/en/static/e0dac4a20d25ea57f18c9a7e89605884/08a84/INSTAR_Alarmserver_Queries_in_Node-RED_04.png 460w", "/en/static/e0dac4a20d25ea57f18c9a7e89605884/ae694/INSTAR_Alarmserver_Queries_in_Node-RED_04.png 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e0dac4a20d25ea57f18c9a7e89605884/ae694/INSTAR_Alarmserver_Queries_in_Node-RED_04.png",
              "alt": "Alarmserver Queries in Node-RED",
              "title": "Alarmserver Queries in Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "import-the-flow-into-node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#import-the-flow-into-node-red",
        "aria-label": "import the flow into node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Import the Flow into Node-RED`}</h2>
    <p>{`You can download the complete flow from the link below:`}</p>
    <p><a parentName="p" {...{
        "href": "/en/Node-RED_Flows/nodered_flow_alarmserver_queries.json"
      }}>{`Download Flow`}</a></p>
    <p>{`Download this JSON data into a text editor and edit the default parameter - IP addresses, logins, etc. - to match your personal setup. You can simply search & replace it.`}</p>
    <p>{`To import it to your Node-RED installation, open the hamburger menu in the top right, choose `}<strong parentName="p">{`Import`}</strong>{` and `}<strong parentName="p">{`Clipboard`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/d74fe/INSTAR_Alarmserver_Queries_in_Node-RED_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACHElEQVQoz1WQXWvTUBzGc9urOdLm0LolDjeHr1/DG2GfQtrOMEUcDDaFfgLdEHSUXmyXwu6E1qHonTBpLdVudeuaNm2XtzZp3prknJ4TSccEfzxXD/yf589DrU7JZrOZTIbn+Sz/dPXJGs+vpdORk8lknq+vb2xtbWxuvsrlHq2sJOLx2/cfPJzn7jKACv8DT5QqsYQJGSJkEhJZ1dKng+2dD6/fHGzv5B6nby0vs/PzgGPnOI5yXU2SyrJcUZSKpomu0gpM2XFV2+56/igMw5fPXtybY+9cn1timCUGsCyXSqUAE0ERQjBGEPqe547Hlg+HEBm2ZXaEekf4aZqddrfWbNc7vb7QFd++f5dMphYXbzIMQ9N0dAzhBKIJJqFtqRfiodz/osi9ZqPfOVeki0pb+GhZpwhhgvH+3h4AIJlM0lMoOMG2KjrC0Vg+1bWa1Du0TM1xTFH82u19g9AJw3BaABFCu7u7NE1zHEfTdDwejwYjE4ihi2FgmZIm/9AHx/pQbbf+9Ltnut7UtF+uq+KoGBcKBQDAwsINAEAikfi3drSsH1gD9ftoeCTL/cbvM6HZkqRyq1U0jHOMQ0JwPp+/NjvLsuzMzEwsFqMQQiPTMkamaxoE+SGOUjzP0PUTyxIwRlfZxLbtk+OTUrFYKl2qRHm+Xzlu1U67tlh1G5+Di7puCIpcdhz56qNLhcPBQNf1IAg8zwtgQAj+C6OhsF+l5GKRAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/e4706/INSTAR_Alarmserver_Queries_in_Node-RED_05.avif 230w", "/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/d1af7/INSTAR_Alarmserver_Queries_in_Node-RED_05.avif 460w", "/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/7f308/INSTAR_Alarmserver_Queries_in_Node-RED_05.avif 920w", "/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/db27b/INSTAR_Alarmserver_Queries_in_Node-RED_05.avif 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/a0b58/INSTAR_Alarmserver_Queries_in_Node-RED_05.webp 230w", "/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/bc10c/INSTAR_Alarmserver_Queries_in_Node-RED_05.webp 460w", "/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/966d8/INSTAR_Alarmserver_Queries_in_Node-RED_05.webp 920w", "/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/4b3db/INSTAR_Alarmserver_Queries_in_Node-RED_05.webp 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/81c8e/INSTAR_Alarmserver_Queries_in_Node-RED_05.png 230w", "/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/08a84/INSTAR_Alarmserver_Queries_in_Node-RED_05.png 460w", "/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/c0255/INSTAR_Alarmserver_Queries_in_Node-RED_05.png 920w", "/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/d74fe/INSTAR_Alarmserver_Queries_in_Node-RED_05.png 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0ff8f213e82c458ef6b3c7fb9cc19ed8/c0255/INSTAR_Alarmserver_Queries_in_Node-RED_05.png",
              "alt": "Alarmserver Queries in Node-RED",
              "title": "Alarmserver Queries in Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Copy & paste the data into the text field and click `}<strong parentName="p">{`Import`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "835px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1572a9bcede0d54cffa6e932c81187dc/f0685/INSTAR_Alarmserver_Queries_in_Node-RED_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB1klEQVQoz2WQ0XKaQBSG83697Uv1pq/RdsbOtGk6pq1EK6BRlgW0JVMbFhZ2FSEICrtaELdFMxMz/a7OxfnmP+e/gBACoI8BhKZlWdPRSJP7V72bt13pjSxfKrLalWTpW7/fG8jy9c1NS5JaitI2jlyMNW00kIHSAcpXoHTu7B+2/Ws2cwgNKQ1938fY8zzPxzgIsO+7QYDv72cQwkaG0NCGyqjXvu1+vu21kzjinHHOqqosy7J6TllWh4NIkkTX9UZu4k3TsKamNYXmZLPJOedFUXDG8zxnjBWM7XY7IcThIOq6FkJkWXYmN0DDgBDqWZZRSiil+AxCSBAElNJlGK7X66dkeMQ4qRCmaYoQsm3bQY7neggh5CLXdR3HQcjBGK+OPMoAgMFwqKoDVVWHqpKuVshFHsZRFIVhGMXRfE7DE4vFnNLlchnH8WNh6mB42e60Pn25lr6PpY/rZLnJm7ebb4vi4SHJ86Ku6/2RqtofhEjT9OlsDega0PVmGuWbTV3Xf8qSc77b7bbbbVmW4lSXELUf7F+9zvJcB+C8sAZdh4yx42azeg5njNf7xYerny9eLuw7YzIxIHwmQwgJIXEcR/9BCCHz+UxWzXfvf1sTw7L+yX8BZxo7beUmM1EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1572a9bcede0d54cffa6e932c81187dc/e4706/INSTAR_Alarmserver_Queries_in_Node-RED_06.avif 230w", "/en/static/1572a9bcede0d54cffa6e932c81187dc/d1af7/INSTAR_Alarmserver_Queries_in_Node-RED_06.avif 460w", "/en/static/1572a9bcede0d54cffa6e932c81187dc/7ace4/INSTAR_Alarmserver_Queries_in_Node-RED_06.avif 835w"],
              "sizes": "(max-width: 835px) 100vw, 835px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1572a9bcede0d54cffa6e932c81187dc/a0b58/INSTAR_Alarmserver_Queries_in_Node-RED_06.webp 230w", "/en/static/1572a9bcede0d54cffa6e932c81187dc/bc10c/INSTAR_Alarmserver_Queries_in_Node-RED_06.webp 460w", "/en/static/1572a9bcede0d54cffa6e932c81187dc/2321d/INSTAR_Alarmserver_Queries_in_Node-RED_06.webp 835w"],
              "sizes": "(max-width: 835px) 100vw, 835px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1572a9bcede0d54cffa6e932c81187dc/81c8e/INSTAR_Alarmserver_Queries_in_Node-RED_06.png 230w", "/en/static/1572a9bcede0d54cffa6e932c81187dc/08a84/INSTAR_Alarmserver_Queries_in_Node-RED_06.png 460w", "/en/static/1572a9bcede0d54cffa6e932c81187dc/f0685/INSTAR_Alarmserver_Queries_in_Node-RED_06.png 835w"],
              "sizes": "(max-width: 835px) 100vw, 835px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1572a9bcede0d54cffa6e932c81187dc/f0685/INSTAR_Alarmserver_Queries_in_Node-RED_06.png",
              "alt": "Alarmserver Queries in Node-RED",
              "title": "Alarmserver Queries in Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And you are all set!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      